@import 'scss/_customVariables.scss';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  padding: 0.25rem 1rem;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out,
    border-color 0.25s ease-in-out;

  &[aria-busy] {
    opacity: 0.8;
    pointer-events: none;

    &:before {
      content: '';
      width: 1rem;
      height: 1rem;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      margin-right: 0.5rem;
      border: 2px solid currentColor;
      border-left-color: transparent;
      animation: spin linear 1s infinite;
    }
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    border-color: currentColor !important;
  }

  &-cta {
    background: #de0024;
    border: 1px solid #de0024;
    color: white !important;
    padding: 0.75rem 1rem;
  }

  &-primary {
    background: rgba(#de0024, 0.12);
    border: 1px solid rgba(#de0024, 0.12);
    color: #de0024;

    &:hover {
      background: rgba(#de0024, 0.5) !important;
      color: white;
    }

    &:disabled {
      border-color: rgba(#de0024, 0.12) !important;
    }
  }

  &-secondary {
    background-color: white;
    border: 1px solid #d9d9d9;
    color: #de0024 !important;

    &:hover {
      background: rgba(#de0024, 0.12) !important;
    }
  }

  &-tertiary {
    color: black;

    &:hover {
      background-color: #eee;
    }
  }

  &-danger {
    background-color: white;
    border: 1px solid $themeRed;
    color: $themeRed;

    &:hover {
      background-color: $themeRed;
      color: white;
    }
  }

  &-success {
    background-color: white;
    border: 1px solid $themeGreen;
    color: $themeGreen;

    &:hover {
      background-color: $themeGreen;
      color: white;
    }

    &.button-filled {
      background-color: $themeGreen;
      color: white;

      &:hover {
        background-color: darken($color: $themeGreen, $amount: 10);
      }
    }
  }

  &-back {
    display: block;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &-full {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.8;
    pointer-events: none;
    cursor: not-allowed;
  }

  &-arrow {
    &[disabled] {
      opacity: 0.3;
    }
  }

  .material-symbols-sharp {
    vertical-align: middle;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cstm-btn {
  font-size: 12px;
  font-weight: 400;
  padding: 8px 15px;
  border-radius: 20px;
  border: 1px solid $primaryRed500;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
  &:active {
    border: 1px solid $primaryRed500 !important;
  }
  &:disabled {
    cursor: not-allowed;
    color: $neutral500;
    background-color: white;
    border: 1px solid $neutral200;
    &:hover,
    &:active {
      background-color: white !important;
      border: 1px solid $neutral200 !important;
    }
  }
  &[aria-busy='true'] {
    &:before {
      content: '';
      width: 1rem;
      height: 1rem;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      margin-right: 0.5rem;
      border: 2px solid currentColor;
      border-left-color: transparent;
      animation: spin linear 1s infinite;
    }
  }
}

.primary-cstm-btn {
  color: white;
  background-color: $primaryRed500;
  &:hover {
    background-color: $primaryRed600;
  }
  &:disabled {
    background-color: $neutral200;
    &:hover,
    &:active {
      border: 1px solid $neutral200 !important;
      background-color: $neutral200 !important;
    }
  }
}

.secondary-cstm-btn {
  color: $primaryRed500;
  background-color: #ffffff;
  &:hover {
    background-color: $primaryRed100;
  }
}

.tertiary-cstm-btn {
  border: 1px solid $neutral200;
  color: black;
  &:hover {
    border: 1px solid $primaryRed500;
    background-color: $primaryRed100;
  }
}

.text-cstm-btn {
  border: none;
  width: fit-content;
  padding: 0 !important;
  color: $primaryRed500;
  span {
    margin-top: 2px;
  }
  &:hover {
    span:not(.material-symbols-sharp) {
      text-decoration: underline;
    }
  }
  &:active {
    border: none !important;
  }
  &:disabled {
    border: none !important;
    &:active,
    &:hover {
      border: none !important;
    }
  }
}
