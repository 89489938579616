.discontinued-label {
  display: block;
  opacity: 0.8;
  font-size: 0.7rem;
}
.dashboard-widgets-modal {
  .cxmeter-modal-header {
    gap: 1rem;
  }
  .cxmeter-modal-content-container {
    left: unset;
    width: 99vw;
    max-width: 500px;
    border-radius: 0;
    .cxmeter-modal-body-content {
      max-height: calc(100vh - 140px);
      height: calc(100vh - 140px);
    }
  }
}

.discontinued-widgets-settings {
  display: flex;
  gap: 1rem;
}

.configWidgetQuestion {
  gap: 1rem;
}

.configWrapper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: end;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.5s;
  transition-property: opacity, visibilty;

  &.configOpen {
    opacity: 1;
    visibility: visible;
    background-color: rgba(black, 0.5);

    .configContainer {
      transform: translateX(0);
    }
  }

  &.configClosed {
    opacity: 0;
    visibility: hidden;

    .configContainer {
      transform: translateX(100vh);
    }
  }

  .configContainer {
    position: absolute;
    width: 40%;
    background-color: white;
    padding: 3rem 4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    transition: transform ease-in-out 0.5s;

    .configHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    .configWidgetList {
      display: flex;
      flex-direction: column;
      padding: 2rem 0;
    }

    .configActions {
      align-self: center;
      width: 100%;
      display: flex;
      justify-content: center;

      > button {
        margin: 0 0.5rem;
        width: 30%;
      }
    }
  }
}
