.blocked__org__modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #0000001f;
  height: 100vh;
  width: 100vw;
  position: relative;
  .org__switcher-container {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .blocked__org__modal-content {
    background-color: white;
    border-radius: 10px;
    max-width: 500px;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .blocked__org__modal-content-body {
      p {
        margin: 0;
      }
    }
    .blocked__org__modal-content-footer {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
