.survey-preview-modal.cxmeter-modal {
  .cxmeter-modal-content-container {
    width: 750px;
    max-width: 95vw;
  }
  .cxmeter-modal-body-content {
    align-items: center;
    height: calc(90vh - 140px);
    justify-content: center;
    @media (max-width: 480px) {
      height: calc(75vh - 140px);
      padding-inline: 0.5rem !important;
    }
  }

  .cxmeter-modal-footer {
    justify-content: center !important;
    padding-block: 10px !important;
  }
}
