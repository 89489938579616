/* Import Bootstrap SASS */
@import '~bootstrap/scss/bootstrap';
@import '_customStyles';
@import './button';
@import './input';
@import './auth';
@import './typography';
@import './utils';
@import './_customVariables.scss';

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: inherit;
  border: 0;
  background: transparent;
}

body {
  font-family: poppins, sans-serif;
  background-color: rgb(252, 252, 252);
  color: $themeGray;
  font-size: 1rem;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a,
.link {
  text-decoration: none;
  color: $LinkColor;
}

.cxmeter-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.small {
    max-width: 480px;

    & ~ .button {
      max-width: 480px;
    }
  }
}

.disabled-element {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.75;
}

.material-symbols-sharp {
  vertical-align: middle;
}

.cxmeter-subtitle {
  font-size: 12px;
}

@media (max-width: $large) {
  html {
    font-size: 95%;
  }
}

@media (max-width: $medium) {
  html {
    font-size: 85%;
  }
}

@media (max-width: $small) {
  html {
    font-size: 75%;
  }
}

.cx-meter-antd-pagination {
  .ant-pagination-item {
    border: 1px solid $neutral200;
    transition-duration: 0s !important;
    color: #000000d9;
    &.ant-pagination-item-active {
      border: 1px solid $primaryRed500;
      color: $primaryRed500;
      font-weight: 400;
      &:hover {
        border: 1px solid $primaryRed500 !important;
        color: $primaryRed500 !important;
        a {
          color: $primaryRed500 !important;
        }
      }
    }
    &:hover {
      background-color: transparent !important;
      border: 1px solid #1677ff;
      color: #1677ff;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid $neutral200;
    transition-duration: 0s !important;
    color: #000000d9;
    &:not(.ant-pagination-disabled):hover {
      background-color: transparent !important;
      border: 1px solid #1677ff;
      color: #1677ff;
      button {
        color: #1677ff;
        background-color: transparent !important;
      }
    }
    button {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
}

.hypertext-translation-link {
  &:hover {
    text-decoration: underline;
  }
}

.ant-table-thead {
  th {
    white-space: nowrap !important;
  }
}
