// Application main Header
@import 'scss/_customVariables';

.review-pagination {
  display: flex;
  align-items: center;
  justify-content: end;
}

main {
  .header {
    margin: 2rem auto;
  }
  .filters-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .filters-container {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      .filter-title {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        p {
          font-size: 1.25rem;
          color: #a2a0a0;
          margin: 0;
        }
        .cstm-btn.text-cstm-btn {
          font-size: 16px;
        }
      }
      .MainFilterWrap {
        flex-wrap: wrap;
        gap: 1rem;
        .controlsWrap {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .search-filter {
          flex-grow: 2 !important;
          min-width: 250px;
          order: 2;
          max-width: 806px !important;
        }
      }
    }
  }
  .chipsWrapper {
    min-height: 5px;
  }
  .tableWrapper {
    margin-top: 2rem;
  }
}

.feedback-table {
  cursor: pointer;
}

th,
td {
  text-align: center !important;
}

.status {
  border-radius: 25px;
  padding: 2px 10px;
  text-align: center;
  font-size: 13px;
  line-height: 1.25rem;
  margin: auto;

  &.res {
    color: white;
    background-color: $themeGreen;
    border: 1px solid $themeGreen;
    font-weight: 500;
  }

  &.unp {
    color: $themeRed;
    border: 1px solid $themeRed;
    background-color: white;
    margin: auto;
  }
}
