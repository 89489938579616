@import '_customVariables';

.cxmeter-container {
  flex: 1;
  width: 100%;
  margin: auto;
  padding: 1rem;
  max-width: 1440px;
  background-color: white;
}

.cxmeter-main {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  @media (min-width: $nav-breakpoint) {
    margin-top: 78px;
  }
}

// Box Shadow
.boxSHadow {
  -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
}

//  Table Styles // Seems redundant CSS

table {
  th,
  td {
    vertical-align: middle;
  }
  th {
    font-weight: 600;
  }

  td {
    .dropdown,
    button.btn {
      height: 100%;
    }

    button.btn {
      background: $themeYellow;
      color: white;
      text-align: left;
      width: 100%;
      border-radius: 0;
      border: 0;
      min-height: 41px;

      &::after {
        text-align: right;
        font-size: 30px;
        position: absolute;
        right: 15px;
        top: 15px;
      }

      &:hover,
      &:focus {
        background-color: $themeYellow;
      }

      &:disabled {
        color: #5d5b5b;
        background-color: #c3c3c3;
        opacity: 1;
      }
    }

    &.edit {
      a {
        color: inherit;
        font-size: 18px;
      }

      img {
        height: 15px;
      }
    }
  }

  .btn {
    &.dropIcon {
      padding-right: 42px;
    }

    > .icon {
      vertical-align: middle;
      font-size: 22px;
      margin-right: 8px;
    }

    .small-icon {
      height: 15px;
      margin-right: 2px;
    }

    span {
      font-size: 11px;
    }
  }
}

.card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem 2rem;
  border: none;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.disabled-blured {
  pointer-events: none;
  filter: blur(3px);
  .cxmeter-modal {
    display: none !important;
  }
}

.dashboard-and-reviews-page-filters {
  .select-with-custom-suffix-icon.rounded-dropdown {
    width: 200px;
    height: 36px;
    .antd-custom-select-dropdown {
      height: 36px;
      .ant-select-selector {
        height: 36px;
      }
    }
  }
  .calendar-btn {
    padding: 5px 10px !important;
  }
  .search-filter {
    label.control-icon {
      border-radius: 50px;
      border: 1px solid #dee1e5;
      padding: 5px 10px !important;
      span {
        color: #798897;
      }
    }
  }
}
