@import '../../../scss/customVariables';

.configWidgetContainer {
  border-bottom: 0.5px solid grey;
  padding: 1.5rem 0;

  .section-configuration-collapse {
    font-family: 'poppins', sans-serif !important;
    background-color: transparent;
    .ant-collapse-header {
      padding: 0;
    }
  }

  .section-configuration-info-container {
    color: #353535 !important;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .section-configuration-title {
      word-break: break-word;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      h5 {
        margin: 0;
      }
    }
  }

  .configWidgetQuestionList {
    padding-top: 1rem;

    .configWidgetQuestion {
      word-break: break-word;
      color: #353535 !important;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0 0.5rem 0.5rem;

      > p {
        margin: 0;
        flex: 1;
      }
    }
  }
}
