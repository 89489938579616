.allowed-choices {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.max-allowed-input {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;

  input {
    width: 3rem;
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    outline: none;
  }
}
.optionsSorterContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  h6 {
    font-size: 16;
    font-weight: 600;
    margin: 0;
  }
  .optionsSorterDropdown {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }
    .antd-custom-select-dropdown {
      height: 30px;
      .ant-select-selector {
        border-radius: 50px;
      }
    }
  }
}

.question-choices-dnd-wrapper {
  .sortableItem {
    .dragIcon {
      font-size: smaller;
    }
  }
}
.choiceWrapper {
  display: flex;
  align-items: center;
  // margin: 10px 0 0 1rem
  margin-left: 1rem;
  padding: 10px 0;
  .choiceInput {
    margin-right: 10px;
    transform: scale(1.25);
  }
  .addIcon {
    color: green;
    margin-left: 10px;
  }
  .crossIcon {
    color: rgb(150, 2, 2);
    margin-left: 10px;
  }
}
.reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .ratingsWrapper {
    order: 2;
  }
  .editorWrapper {
    order: 2;
  }
  .crossIcon {
    order: 1;
  }
}
.last-choice {
  display: flex;
  justify-content: space-between;
  .mock-dnd-icon {
    width: 9.58px;
  }
  .last-choice-data-section {
    width: 98%;
  }
  .add-option-button {
    font-size: 1rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.414); // same as RichTextEditor
    width: 80%;
    text-align: left;
    color: #a2a2a2;
    padding: 2px;
    margin-top: 0.5rem;
  }
}

.other-option-container {
  display: flex;
  margin-top: 1rem;
  margin-left: 1rem;
  .mock-dnd-icon {
    width: 9.8px;
  }
  .other-option {
    display: flex;
    width: 98%;
    align-items: center;
    > div {
      width: 50%;
      .other-option-inputs-container {
        width: 80%;
        display: flex;
        gap: 5px;
        .other-option-text {
          max-width: 75px;
          border-bottom: 1px solid #dee1e5;
          flex-shrink: 0;
          &:focus {
            outline: none;
          }
        }
        .other-option-preview {
          flex-grow: 1;
          min-width: 0;
          border-bottom: 1px solid rgb(222, 225, 229);
        }
      }
    }
    .crossIcon {
      color: #960202;
      margin-left: 2px;
    }
  }
}
