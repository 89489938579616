// Application main Header
@import '../../scss/_customVariables';

@media print {
  .print-analytics-btn {
    display: none !important;
  }
  .dashboard-applied-filters {
    .seprator,
    .create-filter-btn {
      display: none !important;
    }
  }
  .saved-filters {
    .seprator,
    .manage-filters {
      display: none !important;
    }
    &:not(.active-filter-applied) {
      display: none !important;
    }
  }
}

main {
  @media print {
    div {
      page-break-inside: avoid;
    }
  }
  .dashboardHeader {
    margin: 0 auto;
    margin-block: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    .org-detail {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: flex-start;
      .print-analytics-btn {
        white-space: nowrap;
      }
    }
    .dashboard-applied-filters-container {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      .dashboard-applied-filters {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
        .calendar-btn {
          padding: 7px 15px;
          border-radius: 5px;
          border: 1px solid #dee2e6;
          &:hover {
            background-color: #de0024;
            color: white;
            border-color: #de0024;
          }
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        .dashboard-applied-filters {
          order: 2;
        }
        .widgets-config {
          order: 1;
          align-self: flex-end;
        }
      }
    }
    .saved-filters {
      display: flex;
      gap: 1rem;
      align-items: center;
      flex-wrap: wrap;
    }
    .seprator {
      border: 0.5px solid #bcc3cb;
      align-self: stretch;
      margin: 5px 0;
    }
  }

  .spb {
    justify-content: space-between !important;
  }

  .analyticsWrapper {
    display: flex;
    justify-content: space-between;
  }

  .chartsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 2rem;
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .chartCard {
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 350px;

      .chartContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0.5rem;

        .chart-title p {
          // max-width: 300px;
          overflow: hidden;
        }
      }
    }

    .pieChartHolder {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  @media screen and (max-width: $mobileWidth) {
    .headerDropdownsWrapper {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}

.manage-dashboard-filters-modal {
  .cxmeter-modal-content-container {
    width: 400px;
    .cxmeter-modal-body-content {
      border-bottom: none !important;
    }
  }
  .saved-filters-list {
    display: flex;
    gap: 0;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      width: 100%;
      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
        padding-bottom: 1rem;
      }
      &:not(:first-child) {
        padding-top: 1rem;
      }
    }
  }
}

.save-dashboard-filter-modal {
  .cxmeter-modal-content-container {
    width: 500px;
  }
}
