.organization-switcher-trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-org-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .org-search-input {
    position: absolute;
    right: 10px;
    width: 30px;
    padding-inline: 10px;
    outline: none;
    border: none;
    &.ant-input-affix-wrapper-focused {
      outline: none;
      left: 10px;
      width: calc(100% - 20px);
    }
  }
}

.organization-switcher-dropdown {
  width: 400px;
  max-width: 95vw;
  border: 1px solid #dee1e5;
  border-radius: 15px;
  overflow: hidden;
  .ant-dropdown-menu {
    padding: 0;
    .ant-dropdown-menu-item {
      padding: 0;
      background-color: white !important;
      &:hover {
        background-color: transparent !important;
      }
      .ant-dropdown-menu-title-content {
        max-width: 100%;
      }
      .dropdown-content {
        width: 100%;
        .selected-org {
          align-items: center;
          gap: 10px;
          display: flex;
          border-bottom: 1px solid #eef0f2;
          .title {
            font-weight: 600;
            font-size: 16px;
          }
        }
        .switch-org-title {
          color: #505a63;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
        }
        .org-list-container {
          max-height: 355px;
          overflow: auto;
          display: flex;
          flex-direction: column;
          .org-card {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            border-bottom: 0.5px solid #eef0f2;
            .org-title {
              display: flex;
              gap: 10px;
              color: #000000;
              align-items: center;
              flex-grow: 1;
              overflow: auto;
            }
            &.hovered {
              background: #f8f9fa;
            }
          }
        }
      }
    }
  }
}
