@import '../../scss/customVariables';

@media print {
  @page {
    margin: 0.3in 15mm 0.3in 15mm !important;
  }

  .feedback-detail-print-container {
    display: flex !important;
    flex-direction: column;
    gap: 2rem;
  }
  .feedback-picture-attachments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    .feedback-image-container {
      .feedback-image {
        width: 100% !important;
        img {
          max-height: 300px;
        }
      }
      button {
        display: none !important;
      }
    }
  }

  .feedback-audio,
  .feedback-tabs,
  .feedback-separator,
  .comment-input,
  .feedback-detail-container,
  .print-feedback-detail-btn,
  .feedback-header-controls,
  .hide-on-print,
  .feedback-comments .cstm-btn {
    display: none !important;
  }
  .print-tab-heading {
    display: block !important;
  }
  .feedback-attachments,
  .feedback-questions {
    gap: 2rem !important;
  }
}

.feedback-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  width: 100%;
  height: 100%;
  visibility: hidden;

  .feedback-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(black, 0.6);
    transition: all ease-in-out 0.5s;
    transition-property: visibility, opacity;
  }

  &.feedback-open {
    visibility: visible;

    .feedback-content-container {
      transform: translateX(0);
      visibility: visible;

      .spinnerContainer {
        display: flex;
        justify-content: center;
        position: absolute;
        top: calc(50% - 2rem);
        left: calc(50% - 2rem);
      }
    }

    .feedback-backdrop {
      opacity: 1;
      visibility: visible;
    }
  }
}

.feedback-content-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  z-index: 1;
  max-width: min(100vw, 40rem);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  padding: 2rem;
  visibility: hidden;
  transform: translateX(100vh);
  transition: all ease-in-out 0.5s;
  transition-property: transform, visibility;

  @media (min-width: 480px) {
    gap: 2rem;
    padding: 2rem 3rem;
  }
}

.feedback-detail-container {
  display: block;
}
.feedback-detail-print-container {
  display: none;
}

.feedback-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: white !important;
  flex-grow: 1;
}

.feedback-title {
  h2 {
    font-size: 1.25rem;
    margin: 0;
  }

  time {
    font-size: 1rem;
    color: $themeMidGray;
    font-weight: 300;
  }
}

.feedback-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .feedback-header-content {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    img {
      height: 3rem;
    }
  }

  .feedback-header-controls {
    display: flex;
    gap: 0.75rem;
    justify-content: flex-end;
    align-items: center;
    &-email-link {
      color: $primaryRed;
    }
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
    .feedback-header-controls {
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}

.feedback-meta-group {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.feedback-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 2rem;
    height: 2rem;
  }

  p {
    color: $themeMidGray;
    font-size: 0.875rem;
    margin: 0;
    font-weight: 300;
  }

  h3 {
    font-size: 1rem;
    margin: 0;
  }

  .feedback-meta-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}

.feedback-questions {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.feedback-question {
  &-title {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.feedback-checks,
.feedback-radios {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  & > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.feedback-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: min(100%, 25rem);
  gap: 2rem;
}

.feedback-button-group {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.feedback-note {
  border-top: 1px solid $lightGray;
  padding-top: 2rem;

  &-edit {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      font-size: 1.25rem;
    }

    textarea {
      width: 100%;
      height: 100px;
      outline: none;
      border: 1px solid rgba(black, 0.25);
      padding: 0.5rem;
    }
  }
}

.feedback-attachments {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  &-section > p {
    font-weight: 500;
  }
}

.feedback-images {
  display: flex;
  flex-direction: column;
}

@media screen {
  .insights__org__info {
    display: none !important;
  }
  .insights__generation__date {
    display: none !important;
  }
}

.feedback-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid $statusBar;
  padding: 0.5625rem 0.5rem;
  margin: 0;
  .feedback-image {
    width: 50px;
  }
  &:nth-of-type(2) {
    border-top-width: 0;
  }
  & > button {
    color: $primaryRed;
  }
}

.feedback-separator {
  margin: 0;
  color: $lightGray;
  opacity: 1;
}

.feedback-tabs {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid $lightGray;

  & > p {
    margin: 0;
  }

  & > button {
    padding-block: 0.75rem;
  }

  &-active {
    color: $primaryRed;
    border-bottom: 2px solid $primaryRed;
  }
}

.feedback-modal-image {
  width: 100%;
}

.feedback-comments {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & textarea {
    border-radius: 0.125rem;
    border: 1px solid $lightGray;
    padding: 0.5rem;
    width: 100%;
  }

  & button {
    align-self: flex-end;
  }
}

.comment {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e8e8e8;

  &:last-of-type {
    border-bottom: none;
  }

  & p {
    margin: 0;
  }

  .comment-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: rgba(0, 0, 0, 0.6);
    flex: 1;
  }

  .comment-meta {
    display: flex;
    gap: 0.5rem;

    &-user {
      font-weight: 600;
      color: #21272a;
    }
  }
}

.feedback-resolution {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  & p {
    margin: 0;
    padding-top: 0.25rem;
  }

  & div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.resolve-button:disabled {
  background-color: $themeMidGray !important;
}

.feedback-rating {
  & p {
    word-wrap: break-word;
    margin: 0;
    width: 80%;
  }
}
