@import '../../scss/_customVariables';

.payment-dashbord-container {
  .ant-modal-footer {
    margin: 0;

    button {
      border-radius: 0;
      padding: 10px 40px;
      height: unset;
      font-weight: 600;
      border: 2px solid #1577fe;

      &:hover {
        border: 2px solid #4096ff;
      }
    }

    .cancel-btn {
      color: #0f62fe;
    }
  }

  .payment-dashbord-header {
    h3,
    p {
      line-height: 10px;
      margin: 0;
    }
  }

  .current-plan-container {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    .current-plan {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 25px;

      .plan-name {
        margin: 0;
        margin-top: 5px;
        font-size: 18px;
        font-weight: 600;
      }

      .plan-details {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;

        @media (min-width: 768px) {
          flex-direction: row;

          .border-separator {
            padding-left: 15px;
            border-left: 2px solid #d8dade;
          }
        }

        .no-data-indicator {
          text-align: center;
          display: block;
        }

        .no-invoice-price {
          display: block;
        }

        > div {
          h6 {
            color: #9da1a7;
            font-size: 12px;
            font-weight: 600;
          }
          p {
            margin: 0;
          }
        }
      }

      .plan-actions {
        display: flex;
        gap: 15px;

        button,
        button:active {
          border: none !important;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          color: #6d737b;
          display: flex;
          gap: 5px;
          align-items: center;

          svg {
            color: #0f62fe;
          }
        }
      }
    }
  }

  .billing-details {
    > div {
      display: flex;
      gap: 20px;

      h6 {
        font-size: 12px;
        font-weight: 600;
        color: #9da1a7;
        max-width: 150px;
        flex-grow: 1;
      }

      p {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .billing-container {
    .billing-header {
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      p {
        color: #697077;
      }
    }
  }

  .payment-methods-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .primary-payment-method-container {
      .payment-method-detail {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 30px;

        .next-incoice {
          display: flex;
          align-items: center;
          gap: 20px;

          h6 {
            font-size: 12px;
            font-weight: 600;
            color: #9da1a7;
            max-width: 150px;
            flex-grow: 1;
            margin: 0;
          }

          p {
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            color: black;
            display: flex;
            gap: 10px;
            align-items: center;

            small {
              color: #0f62fe;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .backup-payment-method-container {
      margin-top: 10px;

      > p {
        font-size: 12px;
        font-weight: 300;
        color: #697077;
      }
    }

    .payment-method-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        padding: 5px;
        cursor: pointer;
      }
    }
  }
}

button {
  &:active {
    outline: none !important;
    border: none !important;
  }
}

.payment-dashboard__trial-message {
  color: $primaryRed;
  border: 0;
  background-color: rgba(222, 0, 36, 0.08);
}
