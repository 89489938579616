@import 'scss/customVariables';

.onboarding-survey-preview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: center;
  width: 100%;
  align-items: center;
  .survey-device-preview {
    height: calc(100% - 4rem);
  }
  .preview-devices-controls {
    align-self: center;
  }
}

.survey-device-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  background-size: 100% 100%;
  .survey-content-container {
    overflow: auto;
  }
  &.mobile {
    background-image: url(../../../../public/assets/images/mobile-device.svg);
    max-width: 320px;
    max-height: 700px;
    .survey-content-container {
      width: calc(100% - 2rem);
      margin-block: 3rem 2rem;
    }
  }
  &.tablet {
    background-image: url(../../../../public/assets/images/tablet-device.svg);
    max-width: 520px;
    max-height: 600px;
    .survey-content-container {
      width: calc(100% - 3rem);
      margin-block: 1.75rem 2.5rem;
    }
  }
  &.desktop {
    background-image: url(../../../../public/assets/images/desktop-device.svg);
    width: 100%;
    max-width: 100%;
    max-height: 450px;
    .survey-content-container {
      width: calc(100% - 10rem);
      margin-block: 2rem 4rem;
    }
  }
  @media (max-width: 480px) {
    &.mobile {
      .survey-content-container {
        width: calc(100% - 3rem);
        margin-block: 3.5rem 2.5rem;
      }
    }
    &.tablet {
      max-height: 500px;
    }
    &.desktop {
      max-height: 300px;
      .survey-content-container {
        width: calc(100% - 7rem);
        margin-block: 2rem 3rem;
      }
    }
  }
}

.preview-devices-controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  border: 1px solid #dee1e5;
  border-radius: 50px;
  padding: 10px 20px;
  button.cstm-btn {
    border: none;
    &:active {
      border: none !important;
    }
    &.active-device,
    &:hover {
      background-color: #fce6e9;
      color: #de0024;
      border-color: #de0024;
    }
  }
  @media (max-width: 480px) {
    padding: 10px;
    button.cstm-btn {
      font-size: 8px;
      padding: 5px 10px;
      svg {
        height: 15px;
      }
    }
  }
}

.survey-content-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  word-break: break-word;
  .logo {
    margin: auto;
  }
  .section-block {
    padding: 15px;
    max-width: 450px;
    margin: 0 auto;
    width: 100%;
    border-radius: 5px;
    box-shadow:
      0 4px 8px 0 #00000033,
      0 6px 20px 0 #00000030;
    color: black;
    font-weight: 400;
  }
  .nps {
    .nps-reactions {
      display: flex;
      justify-content: space-around;
      align-items: center;
      button {
        img {
          width: 3rem;
          opacity: 0.5;
          &.selected {
            opacity: 1;
          }
        }
      }
    }
  }
  .question-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .question-label {
      display: flex;
      .required {
        color: red;
        margin-left: 5px;
      }
    }
    .question-options {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .question-option {
        display: flex;
        gap: 10px;
        .question-choice-input {
          align-self: flex-start;
          margin-top: 4px;
        }
        &.rating-option {
          justify-content: space-between;
        }
      }
    }
  }
  .text-question-answer {
    background: none;
    width: 100%;
    height: 80px;
    outline: none;
    padding: 0.4em 0.7em 0.4em 0.3em;
    border: 1px solid #d9d9d9;
    text-align: left;
    position: relative;
    padding: 5px;
  }
  .question-attachments {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    .attachment {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      background: #edede9;
      padding: 12px 8px;
      border-radius: 5px;
      border: 1px solid gainsboro;
    }
  }
}
