@import 'scss/_customVariables.scss';

@media print {
  .comparison__print,
  .comparison__controls-reset {
    display: none !important;
  }
}

.comparison__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: 600px;
}

.comparison__filters-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.comparison__header {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
}
.comparison__filters {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.comparison__methods-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.methods-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.methods {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  .method {
    border-radius: 50px;
    font-size: 14px;
    padding: 12px 17px 9px 17px;
    border: 1px solid #dee1e5;
    &.active-method,
    &:active,
    &:hover {
      color: #de0024;
      border: 1px solid #de0024 !important;
      background-color: #de00252e !important;
    }
  }
}
.comparison__preview-type {
  display: flex;
  gap: 1rem;
  align-items: center;
  .cxmeter-dropdown {
    z-index: 0;
  }
}
.comparison__preview-dropdown {
  color: #de0024;
  font-size: 12px;
  border: 1px solid #dee1e5;
  background-color: transparent;
  border-radius: 50px;
  padding: 5px 20px 2px 20px;
  min-width: 133px;
  display: block;
  text-align: center;
  .cxmeter-dropdown-menu {
    top: calc(100% + 1px) !important ;
  }
}

.comparison__results-container {
  padding: 2rem;
  background-color: #eff0f2;
}
.comparison__filters-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  .select-with-custom-suffix-icon {
    height: 34px;
    max-width: 150px;
    .antd-custom-select-dropdown {
      height: 34px !important;
      .ant-select-selector {
        height: 34px;
        .ant-select-selection-search,
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          font-size: 12px;
        }
      }
    }
  }
  .select-with-custom-suffix-icon {
    &.survey-question-list-filter {
      max-width: 312px;
      width: 312px;
    }
  }
  .comparison__controls-reset {
    color: #de0024;
    font-size: 12px;
  }
  .calendar-btn-text {
    border: 1px solid #dee2e6 !important;
    font-size: 12px !important;
    min-width: 150px;
    padding: 8px 15px 6px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50px !important;
    span {
      text-align: start;
      flex-grow: 1;
    }
  }
}
.comparison__results {
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.5rem 1.5rem;
}
.results-header {
  display: grid;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;

  > div {
    p {
      color: #798897;
    }
  }
}
.add-comparison-filter {
  border: 1px solid #dee1e5;
  padding: 7px;
  border-radius: 50px;
  color: #de0024;
}
.comparison__banner--sticky {
  position: sticky;
  top: 5rem;
  background: white;
  padding-block: 2rem;
}

.comparison__print {
  gap: 0.5rem !important;
  color: #de0024;
  border: 1px solid #de0024 !important;
  border-radius: 50px !important;
  padding: 0.5rem 1.25rem !important;
  font-size: 16px !important;

  &:hover {
    background-color: $primaryRed !important;
    color: white;
  }
}

.comparison__table {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.comparison__content-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.comparison__content-header {
  border-radius: 10px;
  border: 1px solid #dee1e5;
  overflow: hidden;
  @media print {
    page-break-inside: avoid;
  }
}

.comparison__values {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 !important;

  & > p {
    min-width: 3rem;
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

.no-results__subtitle {
  color: #697077;
  max-width: 40rem;
}

.compare__values-content {
  display: flex;
  gap: 0.5rem;
  p {
    padding: 0.4rem 0;
    width: 7rem;
    border-radius: 50px;
    text-align: center;
    &.high-feedback-value {
      background-color: #dbf6e1;
    }
  }
  span {
    font-size: 16px;
  }
}

.comparison__banner {
  display: flex;
  justify-content: space-between;
  color: $primaryRed;
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 2px;
  align-items: center;
  word-break: break-word;
}

.comparison__experience {
  margin: 0;
  color: #2280ef;
  font-size: 14px;
}

.comparison__experience-question {
  font-size: 16px;
  color: black;
  margin: 0;
}

.comparison__content {
  display: grid;
  gap: 1rem;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding: 1rem;
  .font-body {
    font-size: 16px;
  }
  &:last-child {
    border-bottom: none;
  }

  & > * {
    flex: 2;
    box-sizing: border-box;
    padding: 0.5rem 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
